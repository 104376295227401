<template>
  <transition name="modal-fade">
    <div class="modal">
      <div class="brdr_r_12 bckg_white modal_block modal_max_width_640">
        <div class="modal_block_head">
          <div class="modal_block_head_title heading_2xl_h3">
            <slot name="head"></slot>
          </div>
          <div class="modal_block_head_item" v-if="!closed">
            <button @click.prevent="closeModal"  class="modal_block_head_item_button">
              <span class="" v-html="CLOSE_ICON_UNION"></span>
              <p style="color: var(--blue);" class="medium_bold_m m_auto_bt">{{$t('close')}}</p>
            </button>
          </div>
        </div>
        <div class="modal_block_body">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { CLOSE_ICON_UNION } from '@/store/icons'
export default {
  name: 'ModalComponent',
  props: {
    closed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      CLOSE_ICON_UNION
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('close-modal')
    }
  }
}
</script>

<style scoped>
/* .modal-fade-enter .modal_block,
.modal-fade-leave-to .modal_block {
  opacity: 0;
  transform: translateY(20px);
}

.modal-fade-enter-active .modal_block {
  transition: all 1s ease;
}

.modal-fade-leave-active .modal_block {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
} */
</style>
