<template>
  <div class="navbar">
    <div class="menu medium_s" @click="closeMenu">
      <img src="/icons/Close=Filled.svg" alt="close">
      <p class="menu_close">{{$t('close')}}</p>
    </div>
    <div class="navbar_block flex bckg_white border_gray_2" style="flex-direction: column-reverse">
      <button
        @click="openOnboard(10)"
        :class="[selected === 8 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height">
        <span class="m_auto_tb fa" v-html="VIDEPLAY_ICON"></span>
        <span class="m_auto_tb fa" v-html="VIDEPLAY_ICON_FILLED"></span>
        <p>{{$t('onboard')}}</p>
      </button>
      <button
        @click="openModal(8)"
        :class="[selected === 8 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" style="margin-top: 50px;">
        <span class="m_auto_tb fa" v-html="INFO_ICON"></span>
        <span class="m_auto_tb fa" v-html="INFO_ICON_FILLED"></span>
        <p>{{$t('info')}}</p>
      </button>
      <!-- <button
        @click="openModal(7)"
        :class="[selected === 7 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" style="margin-top: 50px;">
        <img src="/icons/Rock=Outlined.svg" alt="info" class="fa">
        <img src="/icons/Onboard=Filled.svg" alt="info" class="fa">
        <p>{{$t('faq_menu')}}</p>
      </button> -->
      <!-- <router-link to="/"
        @click.native="selectLink(7)"
        :class="[selected === 7 ? 'selected_link' : '']"
        class="navbar_block_list flex outter main_list_height" >
        <img src="/icons/Rock=Outlined.svg" alt="info" class="fa">
        <img src="/icons/Onboard=Filled.svg" alt="info" class="fa">
        <p>{{$t('onboard')}}</p>
      </router-link> -->
      <router-link to="/catalog"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(6)"
        :class="[selected === 6 ? 'selected_link' : '']">
        <span class="m_auto_tb fa" v-html="CATALOG_ICON"></span>
        <span class="m_auto_tb fa" v-html="CATALOG_ICON_FILLED"></span>
        <p>{{$t('catalog')}}</p>
      </router-link>
      <router-link to="/analytics"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(5)"
        :class="[selected === 5 ? 'selected_link' : '']">
        <span class="m_auto_tb fa" v-html="ANALYTIC_ICON"></span>
        <span class="m_auto_tb fa" v-html="ANALYTIC_ICON_FILLED"></span>
        <p>{{$t('analytics')}}</p>
      </router-link>
      <router-link to="/users-list"
        v-if="uData.rle === 'admin' || uData.rle === 'admin_school'"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(4)"
        :class="[selected === 4 ? 'selected_link' : '']">
        <span class="m_auto_tb fa" v-html="USERS_ICON"></span>
        <span class="m_auto_tb fa" v-html="USERS_ICON_FILLED"></span>
        <p>{{$t('user')}}</p>
      </router-link>
      <router-link to="/schedule"
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(3)"
        :class="[selected === 3 ? 'selected_link' : '']">
        <span class="m_auto_tb fa" v-html="SCHEDULE_ICON"></span>
        <span class="m_auto_tb fa" v-html="SCHEDULE_ICON_FILLED"></span>
        <p>{{$t('schedule')}}</p>
      </router-link>
      <div class="inner" v-if="uData.rle === 'admin_school'">
        <router-link to="/"
          @click.native="selectLink(2)"
          :class="[selected === 2 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="school_course" class="circle_icon">
          <p>{{$t('school_course')}}</p>
        </router-link>
        <router-link to="/lessons"
          @click.native="selectLink(11)"
          :class="[selected === 11 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="school_course" class="circle_icon">
          <p>{{$t('lesson_section')}}</p>
        </router-link>
        <router-link to="/my-course"
          @click.native="selectLink(1)"
          :class="[selected === 1 ? 'selected_link_course' : '']"
          class="navbar_block_list flex add_list_height">
          <img src="/icons/Circle.svg" alt="school_course" class="circle_icon">
          <p>{{$t('my_course')}}</p>
        </router-link>
      </div>
      <div
        v-if="uData.rle === 'admin_school'"
        class="navbar_block_list flex outter main_list_height cocourse_reverseurse"
        :class="[(selected === 2 || selected === 1 || selected === 11) ? 'selected_link' : '']">
        <span class="m_auto_tb fa c1" v-html="PROGRAM_ICON"></span>
        <span class="m_auto_tb fa c2" v-html="PROGRAM_ICON_FILLED"></span>
        <p>{{$t('course')}}</p>
      </div>
      <router-link to="/my-course"
        v-else
        class="navbar_block_list flex outter main_list_height"
        @click.native="selectLink(1)"
        :class="[selected === 1 ? 'selected_link' : '']">
        <span class="m_auto_tb fa" v-html="PROGRAM_ICON"></span>
        <span class="m_auto_tb fa" v-html="PROGRAM_ICON_FILLED"></span>
        <p>{{$t('my_course')}}</p>
      </router-link>
    </div>
    <div>
      <ModalComponent
        v-if="isModalOpen"
        @close-modal="closeModal()">
        <template v-slot:head>
          {{modalTitle}}
        </template>
        <template v-slot:body>
          <InfoPageM v-if="selected === 8"/>
          <questions-page v-if="selected === 7"/>
        </template>
      </ModalComponent>
    </div>
  </div>
</template>

<script>
import { PROGRAM_ICON_FILLED, PROGRAM_ICON, SCHEDULE_ICON, SCHEDULE_ICON_FILLED, USERS_ICON, USERS_ICON_FILLED, ANALYTIC_ICON, ANALYTIC_ICON_FILLED, CATALOG_ICON, CATALOG_ICON_FILLED, INFO_ICON_FILLED, INFO_ICON, VIDEPLAY_ICON, VIDEPLAY_ICON_FILLED } from '@/store/icons'
import ModalComponent from '@/components/modal/ModalComponent'
import InfoPageM from '../views/info/InfoPageM.vue'
import QuestionsPage from '../views/questions/QuestionsPage.vue'

export default {
  name: 'NavbarPage',
  props: {
    isOpen: Boolean,
    isProfile: Boolean
  },
  components: {
    ModalComponent,
    InfoPageM,
    QuestionsPage
  },
  data () {
    return {
      INFO_ICON,
      USERS_ICON,
      CATALOG_ICON,
      PROGRAM_ICON,
      VIDEPLAY_ICON,
      SCHEDULE_ICON,
      ANALYTIC_ICON,
      INFO_ICON_FILLED,
      USERS_ICON_FILLED,
      CATALOG_ICON_FILLED,
      PROGRAM_ICON_FILLED,
      VIDEPLAY_ICON_FILLED,
      ANALYTIC_ICON_FILLED,
      SCHEDULE_ICON_FILLED,
      selected: Number(localStorage.getItem('nb')),
      isModalOpen: false,
      prevSelected: '',
      uData: JSON.parse(localStorage.getItem('uData')),
      modalTitle: ''
    }
  },
  watch: {
    isProfile: function () {
      this.selectProfile()
    }
  },
  methods: {
    checkAccess: function () {
      this.$emit('access-check')
    },
    selectLink: function (ind) {
      if (this.$cookies.isKey('abtCourseTabsSelect')) { this.$cookies.remove('abtCourseTabsSelect') }
      localStorage.setItem('nb', ind)
      this.selected = Number(localStorage.getItem('nb'))
      this.closeMenu()
      this.checkAccess()
    },
    selectProfile: function () {
      this.selected = Number(localStorage.getItem('nb'))
    },
    closeMenu: function () {
      this.$emit('hide-or-show', false)
    },
    // modal
    closeModal: function () {
      this.isModalOpen = false
      this.selected = Number(localStorage.getItem('nb'))
      this.$emit('close-modal')
    },
    openModal: function (numb) {
      this.isModalOpen = true
      this.selected = numb
      this.modalTitle = numb === 8 ? `${this.$t('info')}` : `${this.$t('faq')}`
    },
    openOnboard: function (numb) {
      this.selected = numb
      this.$emit('open-onboard')
    }
  }
}
</script>
