<template>
  <div
    v-if="!next"
    class="hint flex position_5_hint">
    <div class="hint_icon">
      <p v-html="STARS_ICON"></p>
    </div>
    <div class="medium_bold_s hint_text">
      <p>{{$t('press_my_profile')}}</p>
      <button
        @click="nextt"
        class="bttn-secondary brdr_r_8">
        {{$t('ok')}}
      </button>
    </div>
  </div>
</template>

<script>
import { STARS_ICON } from '@/store/icons'

export default {
  name: 'EighthWind',
  props: {
    next: [Boolean]
  },
  data () {
    return {
      STARS_ICON
    }
  },
  methods: {
    closed: function () {
    },
    nextt: function () {
      this.$emit('next-wind', 8)
    }
  }
}
</script>
