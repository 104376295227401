<template>
  <div class="user" v-click-outside="hide">
    <div class="user_block flex" @click="openDropdown">
      <div class="user_block_ava">
        <img
          style="border-radius: 50%;"
          :src="avatar"
          alt="user"
          class=""
          v-if="avatar">
        <p v-else>{{username}}</p>
      </div>
      <div class="user_block_text">
        <p class="role_text">{{role}}</p>
        <p class="username_text">{{uData.user}}</p>
      </div>
      <div class="user_block_icon" :class="[isDropdownOpen ? 'rotate' : '']">
        <img src="/icons/Vector 12 (Stroke).svg" alt="arrow" class="">
      </div>
    </div>
    <div class="user_dropdown" v-if="isDropdownOpen">
      <ul class="brdr_r_8 border_gray_2 bckg_white list_border_bottom">
        <li v-for="(item, index) in listTitle"
          :key="item + index"
          @click="index ? logout() : goProfileSetting()"
          class="medium_s flex">
          <p v-html="listIcons[index]"></p>
          <p>{{item}}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { PERSON_ICON_24, LOGOUT_ICON } from '@/store/icons'

export default {
  name: 'UsersBlock',
  data () {
    return {
      username: '',
      role: '',
      isDropdownOpen: false,
      uData: JSON.parse(localStorage.getItem('uData')),
      avatar: '',
      listIcons: [PERSON_ICON_24, LOGOUT_ICON],
      listTitle: [`${this.$t('my_profile')}`, `${this.$t('exit')}`]
    }
  },
  methods: {
    openDropdown: function () {
      this.isDropdownOpen = !this.isDropdownOpen
    },
    hide: function () {
      this.isDropdownOpen = false
    },
    goProfileSetting: function () {
      localStorage.setItem('nb', 9)
      this.$router.push('/settings')
      this.$emit('my-profile')
      this.hide()
    },
    selectRole: function () {
      switch (this.uData.rle) {
        case 'admin_school': this.role = `${this.$t('admin_school')}`
          break
        case 'admin': this.role = `${this.$t('manager')}`
          break
        case 'teacher': this.role = `${this.$t('teacher')}`
          break
        case 'student': this.role = `${this.$t('student')}`
          break
      }
    },
    // axios functions
    async logout () {
      const cookieKeys = ['abtCourseTabsSelect', '_schlCrsPgSv', '_schlLssnPgSv', '_usrLstPgSv', '_crsCtlgPgSv', '_usrLstSrchSv', '_usrLstCrsSv', '_usrLstLgnSv', '_usrLstTbsSv', '_crsCtlgCtgrSv', '_crsCtlgDgrSv', '_crsCtlgCtSv', '_crsCtlgFrmtSv', '_crsCtlgLnggSv', '_crsCtlgSrchSv', '_crsCtlgFcltySv', '_crsCtlgUnvSv', '_prgrmFcltSv', '_prgrmUnvrSv', '_crsGstCtlgCtSv', '_crsGstCtlgLngSv', '_crsGstCtlgUnvrSv', '_crsGstCtlgFrmtSv', '_crsGstCtlgFcltSv', '_prgrmCtSv', '_prgrmLngSv', '_prgrmFrmtSv', '_prgrmDgrSv', '_crsGstCtlgDgrSv', '_prgrmCtgrSv']
      cookieKeys.forEach((element) => {
        if (this.$cookies.isKey(element)) { this.$cookies.remove(element) }
      })
      await this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/guest-catalog')
        })
    },
    async getUser () {
      await this.$store.dispatch('getProfile')
        .then((response) => {
          this.username = this.uData.user.trim().substring(0, 1) + response.data[0].last_name.trim().substring(0, 1)
        })
        .catch(err => {
          console.log(err.response.data)
        })
    },
    async getAvatar () {
      await this.$store.dispatch('getAvatarProfile', this.uData.id)
        .then((response) => {
          this.avatar = response.data[0].avatar
        })
        .catch(err => {
          console.log(err.response.data)
        })
    }
  },
  mounted () {
    this.getUser()
    this.getAvatar()
    this.selectRole()
  }
}
</script>
